import { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { useProductLink } from 'src/sdk/product/useProductLinkV2'
import SKUSelectorBoughtTogether from 'src/components/product/SkuSelector/SKUSelectorBoughtTogether'
import type { ActiveItem } from 'src/components/ShelfLinx/ShelfBoughtTogether/types'
import { useMobile } from 'src/hooks/useMobile'

import type { Product as ProductRestructure } from '../types/product'
import type { ProductItem } from '../types/productItem'
import ProductCardHeader from './ProductCardHeader'
import ProductCardPrice from './ProductCardPrice'
import ProductBrand from './ProductBrand'
import ProductQuantitySKU from './ProductQuantitySKU'

export interface AmplitudeData {
  term?: string | null
  sort?: string
  page?: number
  selectedFacets?: Array<{ key: string; value: string }>
  itemsPerPage?: number
  totalCountOfRegisters?: number
}

interface ProductCardProps {
  product: ProductRestructure
  drag?: boolean
  index: number
  shelfRef?: string
  shelfEng?: string
  viewSelectVariation?: boolean
  handleChangedItemSKU?: (item: ActiveItem | undefined) => void
  isShelfBoughtTogether?: boolean
  amplitudeData?: AmplitudeData
  type?: string
  title?: string
  isSlider?: boolean
}

export default function ProductCard({
  product,
  viewSelectVariation = false,
  index,
  shelfRef,
  shelfEng,
  drag,
  handleChangedItemSKU,
  isShelfBoughtTogether = false,
  amplitudeData,
  type,
  title,
  isSlider,
}: ProductCardProps) {
  const { isMobile } = useMobile()

  const [viewOptionsCard, setViewOptionsCard] = useState(false)
  const [skuSelected, setSkuSelected] = useState<ProductItem>(product.items[0])
  const [imagesList, setImagesList] = useState(product.items[0].images)
  const [SkuId, setSkuId] = useState<string | undefined>()

  const {
    linkText,
    productName,
    flagCashbackHighlight,
    items: [
      {
        sellerDefault: {
          sellerOffer: { cashback },
        },
      },
    ],
  } = product

  const isB2B = checkEnviromentIsB2B()

  const colors = new Set(product?.items?.map((item) => item.Cor))
  const colorsArray = Array.from(colors).flat()
  const uniqueColorsCount = new Set(colorsArray).size

  const showSkuSelector =
    isShelfBoughtTogether || (viewOptionsCard && !isMobile)

  const showProductName =
    isShelfBoughtTogether || !viewOptionsCard || uniqueColorsCount <= 1

  useEffect(() => {
    const newSku = SkuId
      ? product.items.find((item) => item.itemId === SkuId)
      : product.items[0]

    setImagesList(
      newSku !== undefined ? newSku.images : product.items[0].images
    )

    if (!newSku) {
      return
    }

    setSkuSelected(newSku)
    setImagesList(newSku.images)
  }, [SkuId, product])

  const handleChangedSku = (sku: ActiveItem) => {
    const newSku = product.items.find((item) => item.itemId === sku.itemId)

    if (!newSku) {
      return
    }

    setSkuSelected(newSku)
    setImagesList(newSku.images)
    setSkuId(sku.itemId)

    if (handleChangedItemSKU) {
      const newActiveItem: ActiveItem = {
        itemId: newSku.itemId,
        listedPrice: newSku.offer.listPrice,
        price: newSku.offer.price,
        name: newSku.name,
        quantity: newSku.sellerDefault.sellerOffer.quantity,
        sellerId: newSku.sellerDefault.sellerId,
      }

      handleChangedItemSKU(newActiveItem)
    }
  }

  const productLink = useProductLink({
    product,
    currentSku: skuSelected,
    slug: `${linkText}`,
    index,
    shelfRef,
    shelfEng,
    amplitudeData,
    type,
    title,
  })

  const handleMouse = () => {
    if (!isMobile) {
      setViewOptionsCard(true)
    }
  }

  const handleMouseOut = () => {
    setViewOptionsCard(false)
  }

  return (
    <div
      className="product-card-v2 w-full min-w-[158px] sm:min-w-[254px] md:min-w-[302px] flex justify-start flex-col min-h-[350px] md:min-h-[500px]"
      onMouseEnter={handleMouse}
      onMouseLeave={handleMouseOut}
    >
      <ProductCardHeader
        product={product}
        currentSku={skuSelected}
        viewOptions={viewOptionsCard}
        link={productLink}
        Images={imagesList}
        drag={drag}
        isShelfBoughtTogether={isShelfBoughtTogether}
        isSlider={isSlider}
      />

      {showSkuSelector && (
        <SKUSelectorBoughtTogether
          productItems={product}
          currentSku={skuSelected}
          handleChangedItemSKU={handleChangedSku}
          viewSelectVariation={viewSelectVariation}
        />
      )}

      <Link {...productLink} className="w-full px-3 md:px-0">
        {showProductName && (
          <>
            <ProductBrand brand={product.brand} />

            <div className=" font-inter mt-[0px] font-regular text-[#101010] text-[12px] md:text-[16px] leading-2 md:mt-[8px] line-clamp-2">
              {productName}
            </div>
          </>
        )}
        <ProductCardPrice product={product} currentSku={skuSelected} />

        {skuSelected.isAvailable && cashback && !isB2B && (
          <>
            {flagCashbackHighlight ? (
              <div className="font-inter text-[#006F43] text-[10px] md:text-[12px] font-medium uppercase leading-12 tracking-wide mt-[8px] md:mt-[16px]">
                10% - CASHBACK EM DOBRO!
              </div>
            ) : (
              <div className="font-inter text-[#006F43] text-[10px] md:text-[12px] font-medium uppercase leading-12 tracking-wide mt-[8px] md:mt-[16px]">
                {cashback}
              </div>
            )}
          </>
        )}

        {!skuSelected.isAvailable && (
          <p className="font-inter text-[#101010] text-[14px] leading-12">
            Produto indisponível
          </p>
        )}

        {isMobile && product?.skuSpecifications[0]?.values.length > 1 && (
          <ProductQuantitySKU SkuSpecification={product.skuSpecifications} />
        )}
      </Link>
    </div>
  )
}
