import {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import { Link } from 'gatsby'
import { Image } from 'src/components/ui/Image'

import type { ArrowStyle } from './Arrows'
import Arrows from './Arrows'
import './Carousel.scss'
import type { Video } from '../../product/sections/GalleryImage/types/gallery-image'
import { GalleryMovie } from '../../product/sections/GalleryImage/GalleryMovie'
import Dots from './Dots'
import { useCarouselDrag } from './useCarouselDrag'

type Image = {
  imageUrl: string
  imageText: string
  imageAlt?: string
}

export type CarouselItems = Array<Image | Video>

type CarouselProps = {
  items: CarouselItems
  width: number
  height: number
  dots?: boolean
  arrows?: boolean
  arrowStyle?: ArrowStyle
  enableDrag?: boolean
  href?: string
  Flags?: React.ComponentType
  sectionClassName?: string
  onSlideChange?: () => void
  clickCallback?: (
    item: number,
    event:
      | React.KeyboardEvent<HTMLUListElement>
      | React.MouseEvent<HTMLAnchorElement>
  ) => void
}

const Carousel = forwardRef(
  (
    {
      items,
      width,
      height,
      dots = false,
      arrows = true,
      arrowStyle = 'default',
      enableDrag = true,
      clickCallback,
      href = '#',
      Flags,
      onSlideChange,
      sectionClassName,
    }: CarouselProps,
    ref
  ) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [isDragging, setIsDragging] = useState(false)
    const [dragStartX, setDragStartX] = useState<number>(0)
    const [dragDistance, setDragDistance] = useState<number>(0)
    const hasDragged = useRef(false)
    const totalSlides = items.length

    const carouselDragProps = {
      enableDrag,
      totalSlides,
      currentIndex,
      setCurrentIndex,
      setIsDragging,
      dragStartX,
      setDragStartX,
      isDragging,
      hasDragged,
      setDragDistance,
    }

    const {
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
      handleMouseLeave,
      showSlide,
    } = useCarouselDrag(carouselDragProps)

    const carouselInnerRef = useRef<HTMLUListElement>(null)
    const slideWidth = 100

    useImperativeHandle(ref, () => ({
      showSlide,
      currentIndex,
    }))

    const handleClick = (
      event:
        | React.KeyboardEvent<HTMLUListElement>
        | React.MouseEvent<HTMLAnchorElement>
    ) => {
      if (hasDragged?.current) {
        event.preventDefault()
        hasDragged.current = false

        return
      }

      typeof clickCallback === 'function' && clickCallback(currentIndex, event)
    }

    function handleNext() {
      showSlide(currentIndex + 1)
    }

    function handlePrev() {
      showSlide(currentIndex - 1)
    }

    useEffect(() => {
      typeof onSlideChange === 'function' && onSlideChange()
    }, [currentIndex, onSlideChange])

    return (
      <section
        className={`carousel ${sectionClassName ? ` ${sectionClassName}` : ''}`}
        aria-live="polite"
        aria-label="Carrossel de imagens"
      >
        <h2 className="sr-only" aria-hidden="true">
          Galeria de Imagens
        </h2>

        <ul
          className="carousel-inner cursor-pointer"
          ref={carouselInnerRef}
          style={{
            transform: `translateX(calc(-${currentIndex * slideWidth}% + ${
              isDragging ? dragDistance : 0
            }px))`,
            transition: isDragging ? 'none' : 'transform 0.3s ease',
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleMouseDown}
          onTouchMove={handleMouseMove}
          onTouchEnd={handleMouseUp}
          onKeyDown={(e) => {
            e.key === 'ArrowLeft' && handlePrev()
            e.key === 'ArrowRight' && handleNext()
          }}
          role="presentation"
          aria-roledescription="carousel"
        >
          {items?.map((item, index) => (
            <li
              className="carousel-slide"
              key={index}
              tabIndex={currentIndex === index ? 0 : -1}
              aria-label={`Slide ${index + 1} de ${totalSlides}`}
              aria-roledescription="slide"
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <Link onClick={(event) => handleClick(event)} to={href}>
                {index === 0 && Flags && <Flags />}
                {'url' in item ? (
                  <GalleryMovie {...item} />
                ) : (
                  <Image
                    src={item.imageUrl}
                    alt={item?.imageAlt ?? 'Imagem do carrossel'}
                    title={item?.imageAlt ?? item?.imageText ?? ''}
                    width={width}
                    height={height}
                    loading="lazy"
                    className="w-full h-full object-contain mix-blend-multiply"
                  />
                )}
              </Link>
            </li>
          ))}
        </ul>

        {arrows && (
          <Arrows
            disableArrows={items.length < 2}
            {...{ handleNext, handlePrev, arrowStyle }}
            aria-label="Controle de navegação: próximo e anterior"
          />
        )}

        {dots && (
          <Dots
            currentIndex={currentIndex}
            items={items}
            handleClick={showSlide}
            aria-label="Controle de navegação: ir para o slide"
          />
        )}
      </section>
    )
  }
)

Carousel.displayName = 'Carousel'

export default Carousel
