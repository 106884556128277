const AnimatedCard = ({
  animation,
  digit,
}: {
  animation: string
  digit: string
}) => {
  return (
    <div className={`flipCard ${animation}`}>
      <span>{digit}</span>
    </div>
  )
}

const StaticCard = ({
  position,
  digit,
}: {
  position: string
  digit: string
}) => {
  return (
    <div className={position}>
      <span>{digit}</span>
    </div>
  )
}

const HourDots = () => {
  return <div className="dot">:</div>
}

const formatDigit = (digit: number, unit: string) => {
  const isDayDigit = unit === 'days'

  return `${digit < 10 && !isDayDigit ? 0 : ''}${digit}${isDayDigit ? 'D' : ''}`
}

const FlipUnitContainer = ({
  digit,
  shuffle,
  unit,
}: {
  digit: number
  shuffle: boolean
  unit: string
}) => {
  const currentDigit = digit
  const previousDigit = digit + 1

  // add zero
  const currentDigitStr = formatDigit(currentDigit, unit)
  const previousDigitStr = formatDigit(previousDigit, unit)

  // shuffle digits
  const digit1 = shuffle ? previousDigitStr : currentDigitStr
  const digit2 = !shuffle ? previousDigitStr : currentDigitStr

  // shuffle animations
  const animation1 = shuffle ? 'fold' : 'unfold'
  const animation2 = !shuffle ? 'fold' : 'unfold'

  return (
    <div className="flipUnitContainer">
      <StaticCard position="upperCard" digit={currentDigitStr} />
      <StaticCard position="lowerCard" digit={previousDigitStr} />
      <AnimatedCard digit={digit1} animation={animation1} />
      <AnimatedCard digit={digit2} animation={animation2} />
    </div>
  )
}

export { AnimatedCard, StaticCard, HourDots, FlipUnitContainer, formatDigit }
