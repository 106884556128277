const TshirtIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2243 4.83331H36.5822L36.9485 5.00551C39.7726 6.33308 43.3944 8.02327 46.9924 9.68944L47.6765 10.0062L47.9341 10.7147C48.387 11.9601 50.2542 17.0383 51.5505 20.5636C52.1917 22.3074 52.6931 23.6712 52.8146 24.0031C53.4305 25.6851 52.534 27.5878 50.7478 28.1064L50.7312 28.1112L50.7146 28.1157C50.1026 28.2809 48.9983 28.5755 47.8393 28.8847C46.8494 29.1488 45.8196 29.4236 45.0224 29.6374V48.9375C45.0224 51.2677 43.1234 53.1666 40.7932 53.1666H17.0857C14.7556 53.1666 12.8566 51.2677 12.8566 48.9375L12.8566 29.6434C11.7398 29.3444 10.624 29.0429 9.52996 28.7473C8.74026 28.5339 7.96189 28.3236 7.20261 28.1195C5.36933 27.65 4.42474 25.7374 5.03427 24.0197L5.03845 24.0079C5.79106 21.9325 6.58881 19.7425 7.37131 17.5942C8.25272 15.1744 9.11483 12.8076 9.87146 10.7173L10.1266 10.0124L10.8051 9.69366C12.6094 8.84599 14.4191 8.00469 16.1286 7.21033L16.3667 7.09971C17.989 6.3459 19.5143 5.63718 20.858 5.00551L21.2243 4.83331ZM21.9139 8.51437C20.6856 9.09011 19.3254 9.72215 17.8972 10.3858L17.6562 10.4978C16.1613 11.1924 14.5951 11.9204 13.0245 12.6565C12.3326 14.5651 11.5608 16.6842 10.7746 18.8426C10.0535 20.8224 9.32031 22.8352 8.62596 24.7486C9.239 24.9137 9.86029 25.0816 10.4871 25.2509C12.0163 25.6641 13.5784 26.0862 15.1339 26.4989L16.4816 26.8565V48.9375C16.4816 49.2656 16.7576 49.5416 17.0857 49.5416H40.7932C41.1214 49.5416 41.3974 49.2656 41.3974 48.9375V26.8778L42.7192 26.506C43.1998 26.3708 45.1789 25.8427 46.9833 25.3613C47.8282 25.1358 48.6348 24.9206 49.2313 24.7607C49.0088 24.1551 48.6238 23.1081 48.1653 21.8613C47.0438 18.811 45.4826 14.5652 44.7862 12.6625C41.6135 11.1918 38.4619 9.72116 35.8926 8.51457C35.0795 11.6155 32.2631 13.8958 28.9032 13.8958C25.5433 13.8958 22.7269 11.6154 21.9139 8.51437ZM32.0326 8.45831H25.7739C26.3928 9.54303 27.5595 10.2708 28.9032 10.2708C30.247 10.2708 31.4137 9.54303 32.0326 8.45831Z"
      fill="#F7F8F9"
    />
  </svg>
)

export default TshirtIcon
