import type { ProductItem } from 'src/components/restructure/product/types/productItem'

export const getPriceRange = (items: ProductItem[]) => {
  const prices: number[] = []
  const listPrices: number[] = []

  items.forEach((item) => {
    if (item.sellerDefault.sellerOffer.isAvailable) {
      prices.push(item.sellerDefault.sellerOffer.price)
      listPrices.push(item.sellerDefault.sellerOffer.listPrice)
    }
  })

  if (prices.length === 0) {
    prices.push(0)
  }

  if (listPrices.length === 0) {
    listPrices.push(0)
  }

  return {
    sellingPrice: {
      highPrice: Math.max(...prices),
      lowPrice: Math.min(...prices),
    },
    listPrice: {
      highPrice: Math.max(...listPrices),
      lowPrice: Math.min(...listPrices),
    },
  }
}

const getSkuByUrlParameter = (items: ProductItem[], slug?: string) => {
  const itemId = slug?.split('-')?.pop()

  return items.find((item) => item.itemId === itemId)?.itemId ?? null
}

const getSkuWithLowestPrice = (items: ProductItem[]) => {
  const priceRange = getPriceRange(items)

  return items.find(
    (item) =>
      item.sellerDefault.sellerOffer.price ===
        priceRange.sellingPrice.lowPrice &&
      item.sellerDefault.sellerOffer.isAvailable
  )?.itemId
}

const getSkuWithAvailability = (items: ProductItem[]) => {
  return (
    items.find((item) =>
      item.sellers.find(
        (seller) => seller.commertialOffer.AvailableQuantity > 0
      )
    )?.itemId ?? items[0].itemId
  )
}

export const getInitialItem = (
  items: ProductItem[],
  slug: string,
  skuId: string | undefined | null = null
) => {
  const skuByUrlParameter = getSkuByUrlParameter(items, slug)
  const skuWithLowestPrice = getSkuWithLowestPrice(items)
  const skuWithAvailability = getSkuWithAvailability(items)

  const sku =
    skuId ?? skuByUrlParameter ?? skuWithLowestPrice ?? skuWithAvailability

  return items.find((item) => item.itemId === sku) ?? items[0]
}
