interface Props {
  listType: string
  listName: string
  productsCount: number
  facets?: Array<{ key: string; value: string }>
  productPosition: number
  pageNumber?: number
  sortOrder?: string
}

export function buildProductSelectedObject({
  listName,
  listType,
  productsCount,
  facets,
  productPosition,
  pageNumber,
  sortOrder,
}: Props) {
  const filters = facets ?? []

  return {
    'product list type': listType,
    'product list name': listName,
    'product list level': '1',
    'filter details': filters,
    'number filters applied': filters.length,
    'sort order': sortOrder ?? null,
    'product list page number': pageNumber ?? 1,
    'number of products': productsCount,
    'product position': productPosition,
  }
}
