import { useContext, useMemo, useState } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import { useWishlistContext } from 'src/components/contexts/wishlist-context'
import useWishlist from 'src/hooks/useWishlist'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { getProductQueryObject } from 'src/utils/amplitude/useProductObject'
import type { Product } from 'src/components/restructure/product/types/product'
import WishlistFilledIcon from 'src/components/Icons/WhistlistSelectIcon'
import WishlistEmtpyIcon from 'src/components/Icons/WhistlistIcon'
import { useMobile } from 'src/hooks/useMobile'
import { useAccountContext } from 'src/components/account/context'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import Cookies from 'js-cookie'

import { loginUrl } from '../../../../../../../store.config'

interface WishlistButtonProps {
  product?: Product
  desktopSize: string
  mobileSize: string
  variant?: 'pdp' | 'card'
}

export function WishListButton({
  product: productProps,
  desktopSize,
  mobileSize,
  variant,
}: WishlistButtonProps) {
  const { product: productData } = useContext(ProductContext)
  const [isInWishlist, setIsInWishlist] = useState(false)
  const { isLoggedIn } = useAccountContext()
  const { userWishlist, setUserWishlist, setWishlistId } = useWishlistContext()
  const { isMobile } = useMobile()

  const product = productProps ?? productData

  const { getWishlist, addProductToWishlist, removeProductFromWishlist } =
    useWishlist()

  const refreshWishlist = () => {
    getWishlist().then((wishlistData) => {
      setUserWishlist(wishlistData?.data?.products ?? [])
      setWishlistId(wishlistData?.data?.id)

      return wishlistData?.data?.products
    })
  }

  const sendAmplitudeEvent = () => {
    const productObject = getProductQueryObject(product.items[0])
    const standardObject = getStandardObject()

    if (!standardObject || !productObject) {
      return
    }

    dispatchAmplitudeEvent({
      eventName: 'Product Saved To Wishlist',
      eventData: {
        ...standardObject,
        'product details': productObject,
      },
    })
  }

  const handleButtonClick = async () => {
    const currentPageUrl = window?.location?.pathname ?? ''

    if (!isLoggedIn) {
      Cookies.set('loginURL', window.location.href)

      window.location.href = `${loginUrl}?returnUrl=https://www.decathlon.com.br${currentPageUrl}#loginSuccess`

      return
    }

    if (isInWishlist === true) {
      removeProductFromWishlist(product?.productId).then(refreshWishlist)
      setIsInWishlist(false)

      return
    }

    addProductToWishlist(product?.productId).then(refreshWishlist)
    setIsInWishlist(true)
    sendAmplitudeEvent()
  }

  const wishListIcon = useMemo(() => {
    const wishlistItemId =
      userWishlist?.filter(
        ({ productId }: any) => productId === product?.productId
      ) ?? []

    wishlistItemId.length > 0 ? setIsInWishlist(true) : setIsInWishlist(false)

    return isInWishlist ? (
      <WishlistFilledIcon
        aria-label="Botão para remover produto da lista de favoritos"
        data-testid="colorful-wish-list"
        width={isMobile ? mobileSize : desktopSize}
        height={isMobile ? mobileSize : desktopSize}
      />
    ) : (
      <WishlistEmtpyIcon
        aria-label="Botão para adicionar produto na lista de favoritos"
        data-testid="wish-list-icon"
        width={isMobile ? mobileSize : desktopSize}
        height={isMobile ? mobileSize : desktopSize}
      />
    )
  }, [
    userWishlist,
    isInWishlist,
    isMobile,
    mobileSize,
    desktopSize,
    product?.productId,
  ])

  return (
    <button
      className={
        variant === 'pdp'
          ? 'w-[48px] h-[48px] flex items-center justify-center'
          : ''
      }
      onClick={handleButtonClick}
      aria-label="add or remove item of wishlist"
    >
      {wishListIcon}
    </button>
  )
}
