export const formatSrc = (url: string, width: number, height: number) => {
  try {
    const hasId = url.includes('/arquivos/ids/')

    if (!hasId || !width || !height) {
      return url
    }

    const [id] = url.split('/arquivos/ids/')[1].split('/')[0].split('-')

    const formattedId = `${id}-${width}-${height}`

    return url.replace(id, formattedId)
  } catch {
    return url
  }
}
