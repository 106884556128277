import { useMemo } from 'react'
import { useCheckout } from 'src/sdk/checkout/useCheckout'
import type { ProductItem } from 'src/components/restructure/product/types/productItem'

export function useProductObject() {
  const { orderForm } = useCheckout()

  return useMemo(() => {
    return orderForm?.items?.map(
      (e) => e?.additionalInformation?.analytics?.amplitude
    )
  }, [orderForm?.items])
}

export function getProductQueryObject(currentSku: ProductItem) {
  if (!currentSku) {
    return null
  }

  const amplitude = currentSku?.analytics?.amplitude

  return {
    'product id': amplitude?.product_id,
    name: amplitude?.name,
    'product type': amplitude?.product_type,
    'seller name': amplitude?.seller_name,
    brand: amplitude?.brand,
    sport: amplitude?.sport,
    universe: amplitude?.universe,
    department: amplitude?.department,
    'sub department': amplitude?.sub_department,
    family: amplitude?.family,
    'list price': amplitude?.list_price,
    'display price': amplitude?.display_price,
    gender: amplitude?.gender,
    color: amplitude?.color,
    'carc quantity': amplitude?.carc_quantity,
    'cac quantity': amplitude?.cac_quantity,
    'shopping tool': amplitude?.shopping_tool,
    availability: amplitude?.availability,
    'sku code': amplitude?.sku_code,
    size: amplitude?.size,
    quantity: amplitude?.quantity,
    'purchase price': amplitude?.purchase_price,
    'purchase value': amplitude?.purchase_value,
  }
}
