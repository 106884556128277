import type { CarouselItems } from './Carousel'

type DotsProps = {
  items: CarouselItems
  currentIndex: number
  handleClick: (index: number) => void
}

const Dots = ({ items, currentIndex, handleClick }: DotsProps) => {
  return (
    <div
      className="carousel-dots"
      role="group"
      aria-label="Navegação por pontos do carrossel"
    >
      {items?.map((_, index) => (
        <button
          key={index}
          className={`carousel-dot ${index === currentIndex ? 'active' : ''}`}
          onClick={() => handleClick(index)}
          aria-label={`Ir para o slide ${index + 1}`}
          aria-pressed={index === currentIndex}
          tabIndex={index === currentIndex ? -1 : 0}
        />
      ))}
    </div>
  )
}

export default Dots
