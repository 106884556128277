import { useCallback } from 'react'
import {
  makeProductClickEvent,
  sendEvent,
} from 'src/utils/restructure/analytics'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { buildProductSelectedObject } from 'src/utils/amplitude/buildProductSelectedObject'
import type { AmplitudeData } from 'src/components/restructure/product/ProductCard/ProductCard'
import { getInitialItem } from 'src/components/restructure/utils/get-initial-sku'
import { getProductQueryObject } from 'src/utils/amplitude/useProductObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'

import { useLinxProductClick } from '../linx/hooks/useLinxProductClick'
import { sendSearchClickEvent } from '../analytics/platform/intelligentSearch'

export type ProductLinkOptions = {
  index: number
  product: any
  currentSku: any
  slug: string
  shelfRef?: string
  shelfEng?: string
  amplitudeData?: AmplitudeData
  type?: string
  title?: string
}

export const useProductLink = ({
  index,
  product,
  currentSku,
  slug,
  shelfRef,
  shelfEng,
  amplitudeData,
  type,
  title,
}: ProductLinkOptions) => {
  const { sendProductClickEvent } = useLinxProductClick()
  const standardObject = getStandardObject()
  const {
    page,
    itemsPerPage,
    totalCountOfRegisters,
    sort,
    selectedFacets,
    term,
  } = amplitudeData ?? {}

  const productObject = getProductQueryObject(
    getInitialItem(product.items, slug)
  )

  const getItemPosition = useCallback(() => {
    const currentPosition = index + 1

    if (shelfRef || page === 1) {
      return currentPosition
    }

    if (!page || !itemsPerPage) {
      return currentPosition
    }

    return (page - 1) * itemsPerPage + currentPosition
  }, [index, shelfRef, page, itemsPerPage])

  const triggerProductEvents = useCallback(() => {
    if (product.clickUrl) {
      sendProductClickEvent({ clickUrl: product.clickUrl })
    }

    const productClick = makeProductClickEvent(product, product.items[0], {
      listName: shelfRef ?? 'product card',
      dimension29: shelfEng ?? '',
    })

    sendEvent(productClick)

    sendSearchClickEvent({
      name: 'search_select_item',
      params: {
        url: window.location.href,
        position: index,
        productId: product.productId,
      },
    })

    dispatchAmplitudeEvent({
      eventName: 'Product Selected',
      eventData: {
        ...standardObject,
        'product details': productObject,
        ...buildProductSelectedObject({
          listName: shelfRef ?? term ?? title ?? '',
          listType: `${type ?? 'página de lista de produto'}`,
          productPosition: getItemPosition(),
          productsCount: totalCountOfRegisters ?? 0,
          pageNumber: page,
          sortOrder: sort,
          facets: selectedFacets,
        }),
      },
    })
  }, [
    product,
    shelfRef,
    shelfEng,
    index,
    standardObject,
    productObject,
    term,
    title,
    type,
    getItemPosition,
    totalCountOfRegisters,
    page,
    sort,
    selectedFacets,
    sendProductClickEvent,
  ])

  const onClick = useCallback(() => {
    triggerProductEvents()
  }, [triggerProductEvents])

  const currentSkuURLParam = currentSku?.itemId
    ? `?skuId=${currentSku.itemId}`
    : ''

  if (currentSku.isKit) {
    return {
      to: `/${slug}/kit`,
      onClick,
      'data-testid': 'product-link',
    }
  }

  return {
    to: `/${slug}/p${currentSkuURLParam}`,
    onClick,
    'data-testid': 'product-link',
  }
}
