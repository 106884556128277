interface ProductCardProps {
  brand: string
}

export default function ProductBrand({ brand }: ProductCardProps) {
  return (
    <span className="flex justify-between font-inter text-[12px] md:text-[14px] text-[#616161] font-regular leading-6 ">
      {brand.charAt(0).toUpperCase() + brand.slice(1).toLowerCase()}
    </span>
  )
}
