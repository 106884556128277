import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import type { Dispatch, SetStateAction } from 'react'
import type { ProductItem } from 'src/components/restructure/product/types/productItem'
import type {
  Product,
  SkuSpecification,
} from 'src/components/restructure/product/types/product'

import { getInitialItem } from '../../utils/get-initial-sku'
import type {
  Item,
  FreightSimulationData,
} from '../../common/FreightSimulation/useFreightSimulation'
import { useFreightSimulation } from '../../common/FreightSimulation/useFreightSimulation'

type ProductProviderProps = {
  children: React.ReactNode
  product: Product
  slug: string
  skuId?: string | null
}

type ProductContextProps = {
  product: Product
  currentSku: ProductItem
  initialSku: ProductItem
  specificationMustBeSelected: SkuSpecification | null
  setSpecificationMustBeSelected: Dispatch<
    React.SetStateAction<SkuSpecification | null>
  >
  setCurrentSku: Dispatch<SetStateAction<ProductItem>>
  deliveryIsAvailable: boolean
  setDeliveryIsAvailable: Dispatch<SetStateAction<boolean>>
  setFreightSimulation: (items: Item[], cep: string) => Promise<void>
  isLoadingFreightSimulation: boolean
  freightSimulationValue: FreightSimulationData | null
  triedToAdd: boolean
  setTriedToAdd: Dispatch<SetStateAction<boolean>>
}

export const ProductContext = createContext<ProductContextProps>(
  {} as ProductContextProps
)

export const ProductProvider = ({
  children,
  product,
  slug,
  skuId = null,
}: ProductProviderProps) => {
  const initialSku = getInitialItem(product.items, slug, skuId)
  const { getFreightSimulation } = useFreightSimulation()
  const [triedToAdd, setTriedToAdd] = useState(false)

  const [currentSku, setCurrentSku] = useState(initialSku)
  const [deliveryIsAvailable, setDeliveryIsAvailable] = useState(false)
  const [specificationMustBeSelected, setSpecificationMustBeSelected] =
    useState<SkuSpecification | null>(null)

  const [isLoadingFreightSimulation, setIsLoadingFreightSimulation] =
    useState(false)

  const [freightSimulationValue, setFreightSimulationValue] =
    useState<FreightSimulationData | null>(null)

  useEffect(() => {
    // to change current sku when product is changed
    setCurrentSku(getInitialItem(product.items, slug, skuId))
  }, [product, skuId, slug])

  const setFreightSimulation = useCallback(
    async (items: Item[], cep: string) => {
      setIsLoadingFreightSimulation(true)

      try {
        const response = await getFreightSimulation(items, cep)

        const isDeliveryAvailable = response?.packages?.some(
          (item) => item?.deliveryTypes?.length > 0
        )

        setDeliveryIsAvailable(isDeliveryAvailable ?? false)
        setFreightSimulationValue(response)
      } catch (error) {
        console.error('Error during freight simulation:', error)
      } finally {
        setIsLoadingFreightSimulation(false)
      }
    },
    [
      getFreightSimulation,
      setIsLoadingFreightSimulation,
      setDeliveryIsAvailable,
      setFreightSimulationValue,
    ]
  )

  useEffect(() => {
    async function simulateWhenChangeSKU() {
      const storedCEP = localStorage.getItem('cep')

      if (!storedCEP) {
        return
      }

      setFreightSimulation(
        [
          {
            id: currentSku.itemId,
            seller: currentSku.sellerDefault.sellerId,
            quantity: 1,
          },
        ],
        storedCEP
      )
    }

    simulateWhenChangeSKU()
  }, [currentSku, setFreightSimulation])

  const productContextValue = {
    product,
    initialSku,
    currentSku,
    specificationMustBeSelected,
    setSpecificationMustBeSelected,
    setCurrentSku,
    deliveryIsAvailable,
    setDeliveryIsAvailable,
    setFreightSimulation,
    isLoadingFreightSimulation,
    freightSimulationValue,
    triedToAdd,
    setTriedToAdd,
  }

  return (
    <ProductContext.Provider value={productContextValue}>
      {children}
    </ProductContext.Provider>
  )
}

export const useProductPageContext = () => useContext(ProductContext)
