import { Image } from 'src/components/ui/Image'
import Carousel from 'src/components/restructure/common/Carousel/Carousel'
import { formatSrc } from 'src/components/restructure/utils/formatSrc'
import { Link } from 'gatsby'

import ProductFlag from './ProductFlag'
import type { Product } from '../types/product'
import type { ProductItem } from '../types/productItem'
import { WishListButton } from '../sections/Sidebar/ProductTitle/WishListButton'

type Image = {
  imageUrl: string
  imageText: string
}

interface ProductCardHeaderProps {
  product: Product
  currentSku: ProductItem
  viewOptions: boolean
  link: ProductLink
  drag?: boolean
  Images: Image[]
  isShelfBoughtTogether: boolean
  isSlider?: boolean
}

interface ProductLink {
  to: string
  onClick: () => void
  'data-testid': string
}

export default function ProductCardHeader({
  product,
  currentSku,
  link,
  Images,
  isShelfBoughtTogether,
  drag,
  isSlider = false,
}: ProductCardHeaderProps) {
  const showProductFlag = isShelfBoughtTogether || currentSku.flags?.length > 0

  const canUseDom = typeof window !== 'undefined'

  return (
    <div className="relative mb-[12px]">
      <div className="relative bg-[#eeeff0]">
        {isSlider ? (
          <>
            <Link
              onClick={() =>
                typeof link?.onClick === 'function' && link?.onClick()
              }
              to={link.to}
              className="md:hidden"
            >
              <Image
                src={formatSrc(Images[0].imageUrl, 350, 350)}
                alt={product?.productName ?? 'Imagem do carrossel'}
                title={product?.productName ?? Images[0]?.imageText ?? ''}
                width={302}
                height={302}
                loading="lazy"
                className="w-full h-full object-contain mix-blend-multiply"
              />
            </Link>
            <Carousel
              enableDrag={drag}
              clickCallback={link?.onClick}
              href={link.to}
              items={Images.map((image) => ({
                ...image,
                imageAlt: product.productName,
                imageUrl: formatSrc(image.imageUrl, 350, 350),
              })).slice(0, canUseDom ? Images.length : 1)}
              width={302}
              height={302}
              sectionClassName="hidden md:block"
            />
          </>
        ) : (
          <Carousel
            enableDrag={drag}
            clickCallback={link?.onClick}
            href={link.to}
            items={Images.map((image) => ({
              ...image,
              imageAlt: product.productName,
              imageUrl: formatSrc(image.imageUrl, 350, 350),
            })).slice(0, canUseDom ? Images.length : 1)}
            width={302}
            height={302}
          />
        )}

        <div className="absolute top-2 right-2 sm:top-4 sm:right-4 text-sm z-10 md:hidden">
          <WishListButton
            product={product}
            mobileSize="16"
            desktopSize="24"
            variant="card"
          />
        </div>
      </div>

      {showProductFlag && (
        <ProductFlag
          flag={currentSku.flags[0]}
          className="absolute top-0 left-0 text-[10px] sm:text-xs p-[6px]"
        />
      )}
      <div className="hidden absolute top-[16px] right-[16px] text-sm z-10 md:block">
        <WishListButton
          product={product}
          mobileSize="16"
          desktopSize="24"
          variant="card"
        />
      </div>
    </div>
  )
}
