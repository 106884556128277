import { memo, useCallback } from 'react'
import { stripUrl } from 'src/utils/stripUrl'
import { Image } from 'src/components/ui/Image'
import type {
  ActiveItem,
  Sellers,
} from 'src/components/ShelfLinx/ShelfBoughtTogether/types'
import type { ProductItem } from 'src/components/product/types'
import DktSlider from 'src/components/restructure/common/DktSlider/DktSlider'

import { api } from '../../../../store.config'
import { imageUrlForSize, VARIATION_IMG_SIZE } from './modules/images'
import type { SizeByColor, SizeByQuantity } from './types'
import './SKUSelectorBoughtTogether.scss'

type SKUSelectorBoughtTogetherProps = {
  productItems: any
  handleChangedItemSKU?: (setedItem: ActiveItem) => void
  currentSku: any
  viewSelectVariation?: boolean
}

function SKUSelectorBoughtTogether({
  productItems,
  currentSku,
  handleChangedItemSKU,
  viewSelectVariation = true,
}: SKUSelectorBoughtTogetherProps) {
  const itemsToBeReduced = productItems ?? []

  const sizesByColor = itemsToBeReduced.items.reduce(
    (previousArray: SizeByColor[], item: ProductItem) => {
      const { itemId, name } = item
      const color = item.Cor ? item.Cor.join('') : 'Cor Única'
      const size = item.Tamanho ? item.Tamanho.join('') : 'Tamanho Único'
      const shortSize = item.Tamanho
        ? item.Tamanho.join('')
            .split(' ', 1)
            .join('')
            .split('-', 1)
            .join('')
            .split('ANOS', 1)
            .join('')
        : 'ÚNICO'

      const sellerObject =
        item.sellers.find(
          (seller: Sellers) =>
            seller.commertialOffer.Price > 0 &&
            seller.commertialOffer.AvailableQuantity > 0
        ) ?? item.sellers[0]

      const price = sellerObject.commertialOffer.Price
      const listedPrice = sellerObject.commertialOffer.ListPrice
      const quantity = sellerObject.commertialOffer.AvailableQuantity
      const { sellerId } = sellerObject
      const imageSelector =
        item.images && item?.images[0]?.imageUrl ? item.images[0]?.imageUrl : ''

      const imageURLs = imageSelector.split('/')
      const imageId = imageURLs[imageURLs.length - 2]
      const imageName = imageURLs[imageURLs.length - 1]
      const url = `https://${api.storeId}.vtexassets.com/arquivos/ids/${imageId}/${imageName}`

      const sizeObject = {
        itemId,
        sellerId,
        name,
        url,
        size,
        price,
        listedPrice,
        quantity,
        shortSize,
        hasDiscount: price < listedPrice,
      }

      const foundObject =
        previousArray.length === 0
          ? undefined
          : previousArray.find(
              (sizeColor: SizeByColor) => sizeColor.color === color
            )

      if (!foundObject) {
        const newSizeColor = {
          color,
          image: imageSelector,
          isAvailable: true,
          size: [sizeObject],
          hasDiscount: false,
        }

        previousArray.push(newSizeColor)

        return previousArray
      }

      if (foundObject.size.find((acc) => acc.size === size)) {
        return previousArray
      }

      foundObject.size.push(sizeObject)

      return previousArray
    },
    [] as SizeByColor[]
  )

  const parsedSizesByColor = sizesByColor.map((item: SizeByColor) => {
    const foundItemQuantity = item.size.find(
      (size: SizeByQuantity) => size.quantity > 0
    )

    if (!foundItemQuantity) {
      item.isAvailable = false
    }

    const foundItemDiscount = item.size.find(
      (size: SizeByQuantity) => size.hasDiscount === true
    )

    if (foundItemDiscount) {
      item.hasDiscount = true
    }

    return item
  })

  parsedSizesByColor.sort((previous: SizeByColor, next: SizeByColor) => {
    if (previous.color > next.color) {
      return 1
    }

    if (next.color > previous.color) {
      return -1
    }

    return 0
  })

  // separated to make sonar happy

  function sortItem(item: SizeByColor) {
    item.size.sort((previous, next) => {
      if (previous.shortSize > next.shortSize) {
        return 1
      }

      if (next.shortSize > previous.shortSize) {
        return -1
      }

      return 0
    })
  }

  parsedSizesByColor.forEach((item: SizeByColor) => sortItem(item))

  const selectedItem =
    parsedSizesByColor.find((items: SizeByColor) =>
      items.size.find(
        (size: SizeByQuantity) => size.itemId === currentSku.itemId
      )
    ) ?? parsedSizesByColor[0]

  const selectedSize =
    selectedItem.size.find(
      (size: SizeByQuantity) => size.itemId === currentSku.itemId
    ) ?? selectedItem.size[0]

  function handleSelectColor(newlySelected: SizeByColor) {
    if (!newlySelected.isAvailable) {
      return
    }

    handleSelectedSize(newlySelected.size[0])
  }

  function handleSelectedSize(newlySelected: SizeByQuantity) {
    const newActiveItem: ActiveItem = {
      itemId: newlySelected.itemId,
      listedPrice: newlySelected.listedPrice,
      price: newlySelected.price,
      name: newlySelected.name,
      quantity: newlySelected.quantity,
      sellerId: newlySelected.sellerId,
    }

    callbackChangedItem(newActiveItem)
  }

  const callbackChangedItem = useCallback(
    (newActiveItem: ActiveItem) => {
      handleChangedItemSKU?.(newActiveItem)
    },
    [handleChangedItemSKU]
  )

  const sizeOrder = ['P', 'M', 'G', 'GG', '3G', '4G', '5G', 'GGG', 'GGGG']

  const sortedSizes = [...selectedItem?.size]?.sort(
    (a, b) => sizeOrder.indexOf(a.shortSize) - sizeOrder.indexOf(b.shortSize)
  )

  return (
    <>
      {parsedSizesByColor.length > 1 && (
        <section className="flex gap-2 mb-4">
          {parsedSizesByColor.length > 4 ? (
            <DktSlider
              slidesPerView={4}
              rewind={false}
              autoplay={false}
              className="plp-sku-slider"
              sliderId="sku-slider"
            >
              {parsedSizesByColor.map((option: SizeByColor, index: number) => (
                <div key={`${index}-${option.color}`} className="sku-slider">
                  <button
                    className={`border bg-[#F7F8F9] ${
                      option.color === selectedItem.color
                        ? 'border-restructure-border-information'
                        : 'border-[#fff]'
                    } ${
                      !option.isAvailable
                        ? 'opacity-40'
                        : 'hover:border-restructure-border-information'
                    }`}
                    onClick={() => handleSelectColor(option)}
                    disabled={!option.isAvailable}
                  >
                    <Image
                      src={
                        imageUrlForSize(
                          stripUrl(option.image ?? ''),
                          VARIATION_IMG_SIZE
                        ) ?? ''
                      }
                      srcSet={
                        imageUrlForSize(
                          stripUrl(option.image ?? ''),
                          VARIATION_IMG_SIZE
                        ) ?? ''
                      }
                      alt={option.image ?? ''}
                      className="flex object-fig max-w-full max-h-full mix-blend-multiply"
                      width={56}
                      height={56}
                      loading="lazy"
                    />
                  </button>
                </div>
              ))}
            </DktSlider>
          ) : (
            <>
              {parsedSizesByColor.map((option: SizeByColor) => {
                return (
                  <button
                    key={`${option.color}`}
                    className={`relative flex h-10 w-10 items-center justify-center border bg-[#F7F8F9] sm:h-[56px] sm:w-[56px] ${
                      option.color === selectedItem.color
                        ? 'border-restructure-border-information'
                        : 'border-[#fff]'
                    } ${
                      !option.isAvailable
                        ? 'opacity-40'
                        : 'hover:border-restructure-border-information'
                    }`}
                    onClick={() => handleSelectColor(option)}
                    disabled={!option.isAvailable}
                  >
                    <Image
                      src={
                        imageUrlForSize(
                          stripUrl(option.image ?? ''),
                          VARIATION_IMG_SIZE
                        ) ?? ''
                      }
                      srcSet={
                        imageUrlForSize(
                          stripUrl(option.image ?? ''),
                          VARIATION_IMG_SIZE
                        ) ?? ''
                      }
                      alt={option.image ?? ''}
                      className="flex object-fig max-w-full max-h-full mix-blend-multiply "
                      width={56}
                      height={56}
                      loading="lazy"
                    />
                  </button>
                )
              })}
            </>
          )}
        </section>
      )}

      {viewSelectVariation && (
        <section className="select-variation flex flex-row flex-wrap gap-2 mb-2">
          {sortedSizes.map((option: SizeByQuantity) => {
            const sizeString = option.size
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()

            if (sizeString === 'unico') {
              return <></>
            }

            return (
              <button
                key={`${option.size}`}
                className={`h-[2.25rem] px-2 flex items-center justify-center border rounded-md  ${
                  option === selectedSize
                    ? 'text-restructure-action border-restructure-background-action-1'
                    : 'border-secondary'
                } ${
                  option.quantity > 0
                    ? 'hover:text-restructure-action hover:border-restructure-background-action-1'
                    : 'opacity-40'
                }`}
                onClick={() => {
                  handleSelectedSize(option)
                }}
              >
                <p className="text-base font-inter whitespace-nowrap">
                  {option.size}
                </p>
              </button>
            )
          })}
        </section>
      )}
    </>
  )
}

export default memo(SKUSelectorBoughtTogether)
