import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'

type Props = {
  flag: {
    value: string
    color: string
    textColor: string
    icon: string | null
  }
  className: string
}

function ProductFlag({ flag, className }: Props) {
  const isB2B = checkEnviromentIsB2B()

  if (isB2B && flag?.value === 'Oferta Clube Decathlon') {
    return null
  }

  const isBalckFriday = flag?.value.toLowerCase() === 'black friday'

  return (
    <div
      className={`h-[19px] sm:h-[30px] flex gap-[4px] px-[6px] items-center font-inter leading-[100%] sm:leading-[150%] ${
        isBalckFriday ? 'font-normal' : 'font-medium'
      } w-fit ${className}`}
      style={{ backgroundColor: flag?.color, color: flag?.textColor }}
    >
      {flag?.icon && (
        <img
          className="!w-[6px] sm:!w-[12px] h-auto"
          src={flag.icon}
          alt="Flag icon"
        />
      )}
      {flag?.value ?? ''}
    </div>
  )
}

export default ProductFlag
