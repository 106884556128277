import './arrows.scss'

import { CarouselChevron } from '../../../Icons/CarouselChevron'

export type ArrowStyle = 'default' | 'bottom'

type ArrowsProps = {
  handlePrev: () => void
  handleNext: () => void
  arrowStyle: ArrowStyle
  disableArrows: boolean
}

const Arrows = ({
  handleNext,
  handlePrev,
  arrowStyle,
  disableArrows,
}: ArrowsProps) => {
  return (
    <div
      className={`dkt-arrows-wrapper ${arrowStyle} ${
        disableArrows ? 'disabled' : 'enabled'
      }`}
      role="group"
      aria-label="Controles de navegação do carrossel"
    >
      <button
        className="dkt-carousel-arrow-prev"
        onClick={() => handlePrev()}
        aria-label="Slide anterior"
        disabled={disableArrows}
        tabIndex={disableArrows ? -1 : 0}
      >
        <CarouselChevron />
      </button>
      <button
        className="dkt-carousel-arrow-next"
        onClick={() => handleNext()}
        aria-label="Próximo slide"
        disabled={disableArrows}
        tabIndex={disableArrows ? -1 : 0}
      >
        <CarouselChevron />
      </button>
    </div>
  )
}

export default Arrows
