import { useEffect, useState, useCallback } from 'react'

import './countdown.scss'
import { FlipUnitContainer, HourDots } from './atoms/Atoms'

export type CountdownTheme = 'light' | 'dark' | 'hidden'

type CountdownProps = {
  parsedEndDateOfCountdown?: string
  theme?: CountdownTheme
  location?: 'header' | 'shelf'
  setIsCountdownExpired?: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >
  countdownId: string
  setCountdownStart?: React.Dispatch<React.SetStateAction<boolean>>
}

export const Countdown = ({
  parsedEndDateOfCountdown,
  theme = 'light',
  location = 'header',
  setIsCountdownExpired,
  countdownId,
  setCountdownStart,
}: CountdownProps) => {
  const [days, setDays] = useState(0)
  const [daysShuffle, setDaysShuffle] = useState(true)
  const [hours, setHours] = useState(0)
  const [hoursShuffle, setHoursShuffle] = useState(true)
  const [minutes, setMinutes] = useState(0)
  const [minutesShuffle, setMinutesShuffle] = useState(true)
  const [seconds, setSeconds] = useState(0)
  const [secondsShuffle, setSecondsShuffle] = useState(true)

  const updateState = useCallback(
    (
      newTime: number,
      setTime: React.Dispatch<React.SetStateAction<number>>,
      setShuffle: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      setTime((prev) => {
        if (newTime !== prev) {
          setShuffle((prevShuffle) => !prevShuffle)

          return newTime
        }

        return prev
      })
    },
    []
  )

  const updateTime = useCallback(
    (strLimitDate: string) => {
      const now = new Date().getTime()
      const limitDate = new Date(strLimitDate).getTime()
      const timeDiff = limitDate - now

      if (timeDiff <= 0) {
        updateState(0, setDays, setDaysShuffle)
        updateState(0, setHours, setHoursShuffle)
        updateState(0, setMinutes, setMinutesShuffle)
        updateState(0, setSeconds, setSecondsShuffle)
        setIsCountdownExpired?.(true)

        return
      }

      const newDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24))
      const newHours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )

      const newMinutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))
      const newSeconds = Math.floor((timeDiff % (1000 * 60)) / 1000)

      updateState(newDays, setDays, setDaysShuffle)
      updateState(newHours, setHours, setHoursShuffle)
      updateState(newMinutes, setMinutes, setMinutesShuffle)
      updateState(newSeconds, setSeconds, setSecondsShuffle)
    },
    [setIsCountdownExpired, updateState]
  )

  useEffect(() => {
    if (!parsedEndDateOfCountdown || !setIsCountdownExpired) {
      return undefined
    }

    setCountdownStart?.(true)

    const timerID = setInterval(() => updateTime(parsedEndDateOfCountdown), 50)

    return () => clearInterval(timerID)
  }, [
    parsedEndDateOfCountdown,
    setCountdownStart,
    setIsCountdownExpired,
    updateTime,
  ])

  return (
    <div
      className={`flipClock theme-${theme} location-${location}`}
      id={countdownId}
    >
      {days > 0 && (
        <>
          <FlipUnitContainer unit="days" digit={days} shuffle={daysShuffle} />
          <HourDots />
        </>
      )}
      <FlipUnitContainer unit="hours" digit={hours} shuffle={hoursShuffle} />
      <HourDots />
      <FlipUnitContainer
        unit="minutes"
        digit={minutes}
        shuffle={minutesShuffle}
      />
      <HourDots />
      <FlipUnitContainer
        unit="seconds"
        digit={seconds}
        shuffle={secondsShuffle}
      />
    </div>
  )
}
