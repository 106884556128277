import type { Facet } from '@faststore/sdk/dist/search/useSearchState'

interface Props {
  listType: 'plp' | 'vitrine'
  listName: string
  productsCount: number
  selectedFacets?: Facet[]
  sort?: string
  page?: number
}

export function buildProductListObject({
  listName,
  listType,
  productsCount,
  sort,
  selectedFacets,
  page,
}: Props) {
  const filters = selectedFacets
    ? selectedFacets.map((facet) => ({
        'filter type': facet.key,
        'filter value': facet.value,
      }))
    : []

  return {
    'product list type': listType,
    'product list name': listName,
    'product list level': '1',
    'filter details': filters,
    'number filters applied': filters.length,
    'sort order': sort,
    'product list page number': page ?? 1,
    'number of products': productsCount,
  }
}
