import Section from 'src/components/sections/Section'
import 'src/components/sections/ProductShelf/product-shelf.scss'
import 'react-loading-skeleton/dist/skeleton.css'
import { useMobile } from 'src/hooks/useMobile'
import TshirtIcon from 'src/images/icons/TshirtIcon'

type ProductSkeletonProps = {
  height: number
  width: number
  marginBottom?: number
  icon?: React.ReactNode
}

function ProductSkeletonWithIcon({
  height,
  width,
  marginBottom,
  icon,
}: ProductSkeletonProps) {
  return (
    <div
      style={{
        height,
        width,
        marginBottom,
      }}
      className="flex items-center justify-center bg-[#F0F1F3]"
    >
      {icon}
    </div>
  )
}

function CountdownShelfSkeleton() {
  const { screenWidth } = useMobile()

  const quantityOfShelf = screenWidth > 600 ? 3 : 2

  return (
    <Section
      className="layout__content layout__section shelf-section !p-0 m-0 relative"
      style={{ contentVisibility: 'visible' }}
    >
      <div className="flex items-center justify-center w-screen h-9 absolute left-[-16px] top-[89px] bg-[#FFCD4E] max-w-[1280px] restructure-tablet:top-[112px] sm:left-[-20px] restructure-small-desktop:top-[174px] restructure-small-desktop:h-[52px] md:left-0">
        <p className="text-sm leading-[21px] text-black font-medium tracking-[-0.154px] restructure-small-desktop:text-xl restructure-small-desktop:tracking-normal">
          Esgotadas! Aguarde novas ofertas
        </p>
      </div>
      <ul
        data-product-shelf
        className="layout__content !gap-x-2 sm:!gap-x-6 !p-0 !overflow-x-hidden !min-h-fit"
      >
        {Array.from({ length: quantityOfShelf }, (_, index) => (
          <li key={String(index)}>
            <ProductSkeletonWithIcon
              height={screenWidth >= 900 ? 302 : screenWidth >= 600 ? 200 : 156}
              width={screenWidth >= 900 ? 302 : screenWidth >= 600 ? 200 : 158}
              marginBottom={10}
              icon={<TshirtIcon />}
            />
            <ProductSkeletonWithIcon
              height={15}
              width={screenWidth >= 900 ? 302 : screenWidth >= 600 ? 200 : 158}
              marginBottom={4}
            />
            <ProductSkeletonWithIcon
              height={15}
              width={screenWidth >= 900 ? 197 : screenWidth >= 600 ? 150 : 108}
            />
          </li>
        ))}
        <li>
          <ProductSkeletonWithIcon
            height={screenWidth >= 900 ? 302 : screenWidth >= 600 ? 200 : 156}
            width={screenWidth >= 900 ? 302 : screenWidth >= 600 ? 200 : 158}
            marginBottom={10}
            icon={<TshirtIcon />}
          />
          <ProductSkeletonWithIcon
            height={15}
            width={screenWidth >= 900 ? 302 : screenWidth >= 600 ? 200 : 158}
            marginBottom={4}
          />
          <ProductSkeletonWithIcon
            height={15}
            width={screenWidth >= 900 ? 197 : screenWidth >= 600 ? 150 : 108}
          />
        </li>
      </ul>
    </Section>
  )
}

export default CountdownShelfSkeleton
