export function CarouselChevron({
  width = 19.2,
  height = 9,
  color = '#3e4751',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width={`${width}px`}
      height={`${height}px`}
      focusable="false"
      fill={color}
    >
      <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z" />
    </svg>
  )
}
