import type { CountdownTheme } from 'src/components/common/Countdown/Countdown'
import { Countdown } from 'src/components/common/Countdown/Countdown'
import LightningIcon from 'src/images/icons/LightningIcon'
import { parseCountdownDate } from 'src/utils/parseCountdownDate'

type ShelfCountdownProps = {
  expireDate?: string
  theme?: CountdownTheme
  isCountdownExpired?: boolean
  setIsCountdownExpired: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >
  countdownId?: string
}

const ShelfCountdown = ({
  isCountdownExpired,
  setIsCountdownExpired,
  expireDate,
  theme,
  countdownId,
}: ShelfCountdownProps) => {
  const parsedEndDateOfCountdown = parseCountdownDate(expireDate)

  return (
    <div
      className="w-full flex justify-between items-center py-2 px-4 rounded-[2px] restructure-tablet:w-[435px] restructure-medium-desktop:rounded-[8px] restructure-medium-desktop:w-[953px] restructure-medium-desktop:h-[80px] restructure-medium-desktop:py-5 restructure-medium-desktop:px-6"
      style={{
        background:
          'linear-gradient(122deg, rgb(0, 0, 0) -9%, rgb(47, 56, 189) 26%, rgb(0, 0, 0) 62%)',
      }}
    >
      <h2 className="text-white text-sm leading-[21px] font-normal tracking-[-0.154px] restructure-medium-desktop:flex restructure-medium-desktop:gap-2 restructure-medium-desktop:text-2xl restructure-medium-desktop:leading-[36px] restructure-medium-desktop:tracking-[-0.264px]">
        OFERTAS
        <span className="flex items-center gap-1 tracking-[0.14px] font-black restructure-medium-desktop:gap-2 restructure-medium-desktop:tracking-[0.24px]">
          <LightningIcon />
          RELÂMPAGO
        </span>
      </h2>
      {!isCountdownExpired && (
        <Countdown
          parsedEndDateOfCountdown={parsedEndDateOfCountdown}
          theme={theme}
          location="shelf"
          setIsCountdownExpired={setIsCountdownExpired}
          countdownId={countdownId ?? ''}
        />
      )}
    </div>
  )
}

export default ShelfCountdown
