const LightningIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    className="w-3 h-3 restructure-medium-desktop:w-[18px] restructure-medium-desktop:h-[18px]"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.37956 0.5H10.8308L9.25556 3.65093H13.2898L2.5988 12.5L4.09859 7.26017H1L4.37956 0.5ZM5.01679 1.53121L2.66828 6.22896H5.46623L4.50633 9.58256L10.4268 4.68214H7.58728L9.1625 1.53121H5.01679Z"
      fill="white"
      stroke="white"
      strokeWidth="0.628307"
      strokeLinecap="round"
    />
    <path
      d="M10.0256 1.32031H5.10262L2.64111 6.24332H5.10262L4.28211 10.3458L11.6666 4.60231L8.38462 3.78181L10.0256 1.32031Z"
      fill="white"
      stroke="white"
      strokeWidth="0.418872"
    />
  </svg>
)

export default LightningIcon
